<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="content"
      ></el-page-header>
    </div>
    <el-card class="content mt20">
      <el-form
        label-position="left"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('examType.title')" prop="title">
          <el-input
            v-model="ruleForm.title"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('examType.order')" prop="order">
          <el-input
          @input="
              (res) => {
                this.ruleForm.order = +res;
              }
            "
            @mousewheel.native.prevent  
            @DOMMouseScroll.native.prevent 
            type="number"
            placeholder="정렬순이 높은 건이 우선 노출됩니다."
            v-model="ruleForm.order"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('examType.status')" prop="status">
           <el-select v-model="ruleForm.status" :placeholder="$t('please_select')">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('examType.is_hot')" prop="status">
           <el-select v-model="ruleForm.is_hot" :placeholder="$t('please_select')">
                <el-option
                v-for="item in hotOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="submitForm('ruleForm')">{{
        $t("btnTip.submit")
      }}</el-button>
    </el-card>
  </div>
</template>
<script>
import { createExamType, editExamType, examTypeDetail } from "@/api/index";
export default {
  name: "editExamType",
  data() {
    return {
      ruleForm: {
        title: "",
        order:0,
        status:0,
        is_hot:0
      },
      options:[
          {
              label:this.$t('examType.status0'),
              value:0
          },
          {
              label:this.$t('examType.status1'),
              value:1
          }
      ],
      hotOptions:[
          {
              label:this.$t('examType.is_hot0'),
              value:0
          },
          {
              label:this.$t('examType.is_hot1'),
              value:1
          }
      ],
      rules: {
        title: [
          {
            required: true,
            message: this.$t("examType").title,
            trigger: "blur",
          },
        ],
        order: [
          {
            required: true,
            message: this.$t("examType").order,
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("examType").status,
            trigger: "blur",
          },
        ],
        is_hot: [
          {
            required: true,
            message: this.$t("examType").is_hot,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
    content() {
      return this.id === undefined
        ? this.$t("btnTip").add
        : this.$t("btnTip").edit;
    },
  },
  methods: {
      async submitForm(formName) {
      await this.$refs[formName].validate();
      if (this.id === undefined) {
        await createExamType(this.ruleForm);
      } else {
        await editExamType({id:this.id,...this.ruleForm});
      }
      this.$router.go(-1);
    },
    async get_info() {
      const information = await examTypeDetail(this.id);
      this.ruleForm = { ...information };
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.get_info();
  },
};
</script>